// 主体模板
import CONST from './const';

const TAB_MAIN_TPL = `
              <ul class="nav nav-pills border border-bottom-0 flex-nowrap <% if (options.toolbar.show !== true) { %> d-none <% } %>">
                <% if ( options.toolbar.leftScroll.enable === true ) { %>
                <!--                左边的箭头-->
                <li class="nav-item border-end left-scroll-li" >
                    <button class="nav-link ${CONST.TAB_FIXED_WIDTH_NAV_LINK_CLASS_NAME} ${CONST.TAB_TOOLBAR_MOVE_LEFT_BTN_CLASS_NAME}" >
                    <i class="<%= options.toolbar.leftScroll.icon %>"></i>
                    </button>
                </li>
                <% } %>
                <% if ( options.toolbar.refresh.enable === true ) { %>
                 <!--                刷新按钮 -->
                <li class="nav-item  border-end refresh-li">
                    <button class="nav-link ${CONST.TAB_FIXED_WIDTH_NAV_LINK_CLASS_NAME} ${CONST.TAB_REFRESH_TRIGGER_CLASS_NAME}" >
                        <i class="<%= options.toolbar.refresh.icon %>"></i>
                    </button>
                </li>
                <% } %>
                <!--                中间滚动的区域-->
                <li class="nav-item flex-grow-1">
                    <div class="${CONST.TAB_SCROLL_AREA_CLASS_NAME}"></div>
                </li>
                <% if ( options.toolbar.rightScroll.enable === true ) { %>
                <!--                右边的箭头-->
                <li class="nav-item  border-start rightScroll-li" >
                    <button class="nav-link ${CONST.TAB_FIXED_WIDTH_NAV_LINK_CLASS_NAME} ${CONST.TAB_TOOLBAR_MOVE_RIGHT_BTN_CLASS_NAME}" >
                        <i class="<%= options.toolbar.rightScroll.icon %>"></i>
                    </button>
                </li>
                <% } %>
                <% if ( options.toolbar.moreDropdowns.enable === true ) { %>
                <!--                更多操作下拉菜单 -->
                <li class="nav-item  border-start dropdown" >
                    <button class="nav-link ${CONST.TAB_FIXED_WIDTH_NAV_LINK_CLASS_NAME}" data-bs-toggle="dropdown" >
                        <i class="<%= options.toolbar.moreDropdowns.icon %>"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <% if (options.toolbar.moreDropdowns.items.rollback.enable === true) { %>
                        <li>
                            <button class="dropdown-item ${CONST.TAB_TOOLBAR_DROPDOWN_ROLLBACK_CURRENT_CLASS_NAME}" type="button">
                            <%= options.toolbar.moreDropdowns.items.rollback.text %>
                            </button>
                        </li>
                        <% } %>
                        <% if (options.toolbar.moreDropdowns.items.reload.enable === true) { %>
                        <li>
                            <button class="dropdown-item ${CONST.TAB_TOOLBAR_DROPDOWN_REFRESH_CURRENT_CLASS_NAME}" type="button">
                            <%= options.toolbar.moreDropdowns.items.reload.text %>
                            </button>
                        </li>
                        <% } %>
                        <% if (options.toolbar.moreDropdowns.items.close.enable === true) { %>
                        <li>
                            <button class="dropdown-item ${CONST.TAB_TOOLBAR_DROPDOWN_CLOSE_CURRENT_CLASS_NAME}" type="button">
                            <%= options.toolbar.moreDropdowns.items.close.text %>
                            </button>
                        </li>
                        <% } %>
                        <% if (options.toolbar.moreDropdowns.items.closeOthers.enable === true) { %>
                        <li>
                            <button class="dropdown-item ${CONST.TAB_TOOLBAR_DROPDOWN_CLOSE_OTHER_CLASS_NAME}" type="button">
                            <%= options.toolbar.moreDropdowns.items.closeOthers.text %>
                            </button>
                        </li>
                        <% } %>
                        <% if (options.toolbar.moreDropdowns.items.closeAll.enable === true) { %>
                        <li>
                            <button class="dropdown-item ${CONST.TAB_TOOLBAR_DROPDOWN_CLOSE_ALL_CLASS_NAME}" type="button">
                            <%= options.toolbar.moreDropdowns.items.closeAll.text %>
                            </button>
                        </li>
                        <% } %>
                    </ul>
                </li>
                <% } %>  
                <% if ( options.toolbar.fullscreen.enable === true ) { %>
                <!-- 全屏实现 -->
                <li class="nav-item  border-start fullscreen-li">
                    <button class="nav-link ${CONST.TAB_FIXED_WIDTH_NAV_LINK_CLASS_NAME} ${CONST.TAB_FULLSCREEN_TRIGGER_CLASS_NAME}">
                        <i class="<%= options.toolbar.fullscreen.icon %>"></i>
                    </button>
                </li>
                <% } %>
            </ul>
            <!-- 面板容器 -->
            <div class="flex-grow-1 border position-relative"></div>`;

// tab的遮罩层
const TAB_LOADING_TPL = `
     <!--loading-->
     <div class="position-absolute start-0 end-0 top-0 bottom-0 bg-light bg-body-tertiary show d-flex align-items-center justify-content-center">
         <div class="spinner-border text-secondary text-body-tertiary" role="status">
         <span class="visually-hidden">Loading...</span>
         </div>
    </div>
`;


// tab面板模板
const TAB_PANE_TPL = `
        <div class="h-100 w-100 position-relative" ${CONST.TAB_ID_KEY}="<%= option.id %>">
            ${TAB_LOADING_TPL}    
            <iframe src="<%= option.url %>" class="d-block w-100 h-100"></iframe>
        </div>
`

//右键菜单的容器模板
const TBA_CONTEXTMENU_TPL = `
            <div class="list-group shadow-sm ${CONST.TAB_CONTEXTMENU_CONTAINER_CLASS_NAME} ${CONST.TAB_CONTEXTMENU_CONTAINER_CLASS_NAME}-<%= id %>">
                <% if ( options.tabConfig.contextmenu.items.reload.enable === true ) { %>
                <button type="button" class="list-group-item list-group-item-action ${CONST.TAB_CONTEXTMENU_REFRESH_CLASS_NAME}">
                <%= options.tabConfig.contextmenu.items.reload.text %>
                </button>
                <% } %>
                <% if ( options.tabConfig.contextmenu.items.close.enable === true ) { %>
                <button type="button" class="list-group-item list-group-item-action ${CONST.TAB_CONTEXTMENU_CLOSE_CLASS_NAME}">
                <%= options.tabConfig.contextmenu.items.close.text %>
                </button>
                <% } %>
                <% if ( options.tabConfig.contextmenu.items.closeOthers.enable === true ) { %>
                <button type="button" class="list-group-item list-group-item-action ${CONST.TAB_CONTEXTMENU_CLOSE_OTHER_CLASS_NAME}">
                <%= options.tabConfig.contextmenu.items.closeOthers.text %>
                </button>
                <% } %>
            </div>`;


// 每个tab的模板
const TAB_TPL = `
                    <button <% if (options.tabConfig.dragSort === true) { %> draggable="true" <% } %> class="nav-link flex-shrink-0  border-end position-relative <% if (options.tabConfig.closeBtn.showOnHover === true) { %>${CONST.TAB_SHOWONHOVER_CLASS_NAME}<% } %>" type="button" ${CONST.TAB_URL_KEY}="<%= option.url %>"  ${CONST.TAB_ID_KEY}="<%= option.id %>">
                     <%= option.title %>
                     <% if (options.tabConfig.closeBtn.enable === true) { %>
                        <% if (option.close === true) { %>
                        <i class="<%= options.tabConfig.closeBtn.icon %> ${CONST.TAB_CLOSE_BTN_ICON_CLASS_NAME}"></i>
                        <% } %>
                     <% } %>
                    </button>`;

export default {
    TAB_MAIN_TPL,
    TAB_LOADING_TPL,
    TAB_PANE_TPL,
    TBA_CONTEXTMENU_TPL,
    TAB_TPL
}