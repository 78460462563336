const TAB_ID_KEY = 'data-tab-id';
const TAB_URL_KEY = 'data-tab-url';
//命名空间
const TAB_NAMESPACE = 'quicktab';
// tab激活的类名
const TAB_ACTIVE_CLASS_NAME = 'active';
// tab滚动区域的类名
const TAB_SCROLL_AREA_CLASS_NAME = 'scroll_area';
// tab全屏类
const TAB_FULLSCREEN_CLASS_NAME = 'qt-fullscreen';
// tab隐藏类
const TAB_PANE_HIDE_CLASS_NAME = 'outing';
// tab关闭图标挂载类名
const TAB_CLOSE_BTN_ICON_CLASS_NAME = 'qt-close';

// 用于更多下拉菜单展开时给容器挂载的阻止鼠标事件的类
const TAB_MORE_ACTION_DROPDOWNS_POINTER_EVENTS_CLASS_NAME = 'more-menu-pen';
// 全屏触发class
const TAB_FULLSCREEN_TRIGGER_CLASS_NAME = 'fullscreen';
//刷新触发class
const TAB_REFRESH_TRIGGER_CLASS_NAME = 'refresh-toolbar-btn';
// 顶部菜单左滚动
const TAB_TOOLBAR_MOVE_LEFT_BTN_CLASS_NAME = 'move-left-btn';
//顶部菜单 右滚动 按钮
const TAB_TOOLBAR_MOVE_RIGHT_BTN_CLASS_NAME = 'move-right-btn';
// 顶部下拉菜单回到当前按钮
const TAB_TOOLBAR_DROPDOWN_ROLLBACK_CURRENT_CLASS_NAME = 'dropdown-rollback-current-btn';
// 顶部下拉菜单刷新当前按钮
const TAB_TOOLBAR_DROPDOWN_REFRESH_CURRENT_CLASS_NAME = 'dropdown-refresh-current-btn';
// 顶部下拉菜单关闭当前按钮
const TAB_TOOLBAR_DROPDOWN_CLOSE_CURRENT_CLASS_NAME = 'dropdown-close-current-btn';
// 顶部下拉菜单关闭当前按钮
const TAB_TOOLBAR_DROPDOWN_CLOSE_OTHER_CLASS_NAME = 'dropdown-close-other-btn';
// 顶部下拉菜单关闭全部按钮
const TAB_TOOLBAR_DROPDOWN_CLOSE_ALL_CLASS_NAME = 'dropdown-close-all-btn';

//右键菜单容器类
const TAB_CONTEXTMENU_CONTAINER_CLASS_NAME = `${TAB_NAMESPACE}-contextmenu`;

// 用于tab右键菜单时给容器挂载的阻止鼠标事件的类
const TAB_CONTEXTMENU_POINTER_EVENTS_CLASS_NAME = 'pen';
// 右键菜单刷新的类名
const TAB_CONTEXTMENU_REFRESH_CLASS_NAME = 'contextmenu-refresh-btn';
// 右键菜单关闭的类名
const TAB_CONTEXTMENU_CLOSE_CLASS_NAME = 'contextmenu-close-btn';
// 右键菜单关闭其它的类名
const TAB_CONTEXTMENU_CLOSE_OTHER_CLASS_NAME = 'contextmenu-close-other-btn';
// 固定宽度nav-link
const TAB_FIXED_WIDTH_NAV_LINK_CLASS_NAME = 'iconbtn';
// tab鼠标移入才展示关闭按钮类名
const TAB_SHOWONHOVER_CLASS_NAME = 'showOnHover';

export default {
    TAB_ID_KEY,
    TAB_URL_KEY,
    TAB_NAMESPACE,
    TAB_ACTIVE_CLASS_NAME,
    TAB_SCROLL_AREA_CLASS_NAME,
    TAB_FULLSCREEN_CLASS_NAME,
    TAB_PANE_HIDE_CLASS_NAME,
    TAB_CLOSE_BTN_ICON_CLASS_NAME,
    TAB_CONTEXTMENU_POINTER_EVENTS_CLASS_NAME,
    TAB_MORE_ACTION_DROPDOWNS_POINTER_EVENTS_CLASS_NAME,
    TAB_FULLSCREEN_TRIGGER_CLASS_NAME,
    TAB_REFRESH_TRIGGER_CLASS_NAME,
    TAB_TOOLBAR_MOVE_LEFT_BTN_CLASS_NAME,
    TAB_TOOLBAR_MOVE_RIGHT_BTN_CLASS_NAME,
    TAB_TOOLBAR_DROPDOWN_ROLLBACK_CURRENT_CLASS_NAME,
    TAB_TOOLBAR_DROPDOWN_REFRESH_CURRENT_CLASS_NAME,
    TAB_TOOLBAR_DROPDOWN_CLOSE_CURRENT_CLASS_NAME,
    TAB_TOOLBAR_DROPDOWN_CLOSE_OTHER_CLASS_NAME,
    TAB_TOOLBAR_DROPDOWN_CLOSE_ALL_CLASS_NAME,
    TAB_CONTEXTMENU_REFRESH_CLASS_NAME,
    TAB_CONTEXTMENU_CLOSE_CLASS_NAME,
    TAB_CONTEXTMENU_CLOSE_OTHER_CLASS_NAME,
    TAB_CONTEXTMENU_CONTAINER_CLASS_NAME,
    TAB_FIXED_WIDTH_NAV_LINK_CLASS_NAME,
    TAB_SHOWONHOVER_CLASS_NAME,
}