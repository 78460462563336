// quicktab插件默认参数
const DEFAULT = {
    //dom选择器
    selector: '',
    //最小高度
    minHeight: '',
    //高度
    height: '',
    //宽
    width: '',
    //缓存  false:不缓存  "sessionStorage"  "localStorage"
    cache: false,
    //初始tab
    tabs: [],
    //工具栏配置项
    toolbar: {
        //是否显示工具栏
        show: true,
        //向左滚动
        leftScroll: {
            enable: true,
            icon: 'bi bi-caret-left',
        },
        //刷新按钮
        refresh: {
            enable: false,
            icon: 'bi bi-arrow-clockwise',
        },
        //向右边滚动
        rightScroll: {
            enable: true,
            icon: 'bi bi-caret-right',
        },
        //全屏功能配置
        fullscreen: {
            //是否开启全屏, true:开启全屏 false:关闭该功能
            enable: false,
            //图标
            icon: 'bi bi-arrows-fullscreen',
            //退出全屏的图标
            exitIcon: 'bi bi-fullscreen-exit',
            //全屏自定义函数
            fullscreen: null,
            //退出全屏自定义函数
            exitFullscreen: null,
        },
        //是否启用更多操作下拉菜单
        moreDropdowns: {
            enable: true,
            icon: 'bi bi-caret-down',
            items: {
                //回到当前
                rollback: {
                    enable: true,
                    text: '回到当前'
                },
                reload: {
                    enable: true,
                    text: '刷新当前'
                },
                close: {
                    enable: true,
                    text: '关闭当前'
                },
                closeOthers: {
                    enable: true,
                    text: '关闭其它'
                },
                closeAll: {
                    enable: true,
                    text: '关闭所有'
                }
            }
        },
    },
    //tab配置
    tabConfig: {
        //右键菜单是否开启
        contextmenu: {
            enable: false,
            items: {
                reload: {
                    enable: true,
                    text: '刷新'
                },
                close: {
                    enable: true,
                    text: '关闭'
                },
                closeOthers: {
                    enable: true,
                    text: '关闭其它'
                }
            }
        },
        //true: 鼠标滚动切换tab  false:关闭该功能
        mouseWheelToggleTab: false,
        //最大数量 >=1 时生效, null:表示无限制, 注意：提供的初始化tabs中有close:false的tab除外
        maxNum: null,
        //关闭按钮
        closeBtn: {
            enable: true,
            icon: 'bi bi-x',
            showOnHover: true,
        },
        //当浏览器窗口大小改变时是否自动回到当前激活的tab的所在位置(居中)
        windowResizeRollback: true,
        //tab单击时滚动到当前所在位置(自动居中)  false:不启用 true:启用
        clickRollback: false,
        //可拖动排序 false:不启用 true:启用
        dragSort: false,
    },
    //tab被激活的事件
    onTabActivated: null,
    //tab加载完毕事件
    onTabLoaded: null,
    //tab遮罩层加载完毕的事件
    onTabMaskTransitionend: null,
    //实例化完毕回调
    onInit: null,
    //tab被点击回调事件
    onTabClick: null
}

//单个tab的默认选项
const TABDEFAULT = {
    //标题
    title: '新标签页',
    //连接
    url: '',
    //是否可以关闭
    close: true
};

export default {
    DEFAULT,
    TABDEFAULT
}