import CONST from './const';

const style =  `
                .${CONST.TAB_NAMESPACE}{
                    height:222px;
                    background-color: var(--bs-body-bg);
                    display: flex;
                    flex-direction: column;
                }
            
                /*  面板移除样式 */
                .${CONST.TAB_NAMESPACE} .${CONST.TAB_PANE_HIDE_CLASS_NAME} {
                    position: absolute !important;
                    transform: translateY(101%);
                }
            
                /*去除所有的圆角*/
                .${CONST.TAB_NAMESPACE} .nav-pills .nav-link {
                    border-radius: 0;
                }
            
                .${CONST.TAB_NAMESPACE} .nav-pills .nav-link .${CONST.TAB_CLOSE_BTN_ICON_CLASS_NAME} {
                    position: absolute;
                    top: -3px;
                    right: -1px;
                    cursor: pointer;
                    font-size: 16px;
                }
            
                .${CONST.TAB_NAMESPACE} .nav-pills .nav-link .${CONST.TAB_CLOSE_BTN_ICON_CLASS_NAME}:hover {
                    color: #f00;
                }
                
                .${CONST.TAB_NAMESPACE} .nav-pills .nav-link.${CONST.TAB_SHOWONHOVER_CLASS_NAME} .${CONST.TAB_CLOSE_BTN_ICON_CLASS_NAME} {
                    display: none;
                }
                
                .${CONST.TAB_NAMESPACE} .nav-pills .nav-link.${CONST.TAB_SHOWONHOVER_CLASS_NAME}:hover .${CONST.TAB_CLOSE_BTN_ICON_CLASS_NAME} {
                    display: inline-block;
                }
                
                
                .${CONST.TAB_NAMESPACE} li.flex-grow-1 {
                    width:0;
                }
                /*必须添加该样式否则滚动条会超出*/
                .${CONST.TAB_NAMESPACE} div.flex-grow-1 {
                    overflow: hidden;
                }
                
         
                .${CONST.TAB_NAMESPACE} li.flex-grow-1 .${CONST.TAB_SCROLL_AREA_CLASS_NAME}{
                    position: relative;
                    display:flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    overflow: scroll;
                    scrollbar-width: none;
                    -ms-overflow-style: none;
                    height: 100%;
                    overflow-y: hidden;
                }
                
                  /* 谷歌浏览器 */
                .${CONST.TAB_NAMESPACE} li.flex-grow-1 .${CONST.TAB_SCROLL_AREA_CLASS_NAME}::-webkit-scrollbar {
                    display: none; 
                }
                
                /*中间的tab设置一个最大的宽度超出部分显示省略号*/
                .${CONST.TAB_NAMESPACE} li.flex-grow-1 .nav-link {
                    min-width: 0;
                    max-width: 180px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            
                /*给图标按钮设置一个固定宽度，否则滚动到指定tab位置时不精确*/
                .${CONST.TAB_NAMESPACE} .${CONST.TAB_FIXED_WIDTH_NAV_LINK_CLASS_NAME}{
                    width: 48px;
                }
            
                .${CONST.TAB_NAMESPACE} .show {
                    opacity: 1;
                }
            
                .${CONST.TAB_NAMESPACE} .hide {
                    opacity: 0;
                    transition: opacity 800ms;
                }
            
                .${CONST.TAB_NAMESPACE}.${CONST.TAB_FULLSCREEN_CLASS_NAME} {
                    height: 100% !important;
                    width: 100% !important;
                    position: fixed !important;
                    z-index: 99999 !important;
                    top: 0 !important;
                    bottom: 0 !important;
                    left: 0 !important;
                    right: 0 !important;
                }
                
                .${CONST.TAB_CONTEXTMENU_CONTAINER_CLASS_NAME} {
                  display: none;
                  position: fixed;
                  width: 100px;
                  background: #fff;
                  z-index: 999999 !important;
                }
                
                
                .${CONST.TAB_NAMESPACE}.${CONST.TAB_CONTEXTMENU_POINTER_EVENTS_CLASS_NAME} iframe {
                  pointer-events: none;
                }
                
                .${CONST.TAB_NAMESPACE}.${CONST.TAB_MORE_ACTION_DROPDOWNS_POINTER_EVENTS_CLASS_NAME} iframe {
                  pointer-events: none;
                }
                
                @media (max-width: 400.98px) {
                    
                    .${CONST.TAB_NAMESPACE} .left-scroll-li{
                        display: none !important;
                    }
                    
                    .${CONST.TAB_NAMESPACE} .refresh-li{
                        display: none !important;
                    }
                    
                    .${CONST.TAB_NAMESPACE} .rightScroll-li{
                        display: none !important;
                    }
                   
                    
                }
            `;

export default {
    style
}


